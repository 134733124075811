.column-container {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    margin-bottom: 100px;
    font-size: 18px;
}

.column {
    margin-top: 80px;
    margin-bottom: 100px;
    font-size: 18px;
    width: 300px;
}


.heading-list {
    text-align: start;
    margin-left: 50px;
    color: #20B2AA;
}

.heading-list-secondary {
    text-align: start;
    margin-left: 50px;
}

ul {
    list-style-type: circle;
    margin-left: 60px;
}
  
li {
    margin-bottom: 10px;
}

@media screen and (max-width: 950px) {
    .column-container {
      flex-direction: column;
      align-items: start;
    }
  
    .column {
      width: 100%;
      margin: 0 auto;
    }
}