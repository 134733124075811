.imageForCylinderPage3 {
    width: 700px;
    height: 450px;
    margin-top: 55px;
}

.section-title-for-cylinderPage1{
    color: gray;
    font-size: 30px;
    text-align: start;
    margin-top: 100px;
    margin-left: 50px;
}

.section-title-for-cylinderPage2{
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 40px;
}

.cylinder-text {
    text-align: start;
    margin-left: 50px;
    padding: 5px;
    font-size: 20px;
}

.row {
    margin-bottom: 80px;
}

@media screen and (max-width: 950px) {
    .section-title-for-cylinderPage1{
        font-size: 17px;
    }
    
    .section-title-for-cylinderPage2{
        font-size: 28px;
    }

    .imageForCylinderPage3 {
        width: 100%;
        height: auto;
        margin-top: 30px;
    }

    .section-title-for-cylinderPage1 {
        margin-left: 20px;
    }

    .section-title-for-cylinderPage2 {
        margin-left: 20px;
    }

    .cylinder-text {
        margin-left: 20px;
    }

    .row {
        margin-bottom: 40px;
    }
}