.section-title {
    color: gray;
    font-size: 30px;
    text-align: start;
    margin-top: 60px;
    margin-left: 60px;
    margin-bottom: 10px;
}

.section-description {
    color: #20B2AA;
    font-size: 58px;
    text-align: start;
    margin-bottom: 80px;
    margin-left: 60px;
}

.card {
    margin-bottom: 30px;
    border: none !important;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;
}

.card-title {
    font-size: 25px;
}

.card-text {
    color: gray;
    font-size: 18px;
}

.carousel-item img {
    height: 600px;
    width: 200px;
    object-fit: contain;
}

@media only screen and (max-width: 950px) {
    .section-title {
        font-size: 17px;
        margin-left: 30px;
    }

    .section-description {
        font-size: 28px;
        margin-left: 30px;
    }

    .card {
        font-size: 16px;
        margin-left: 30px;
    }

    .carousel-item img {
        height: auto;
        width: 100%;
    }
}


