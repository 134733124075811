.headingofOverhaul {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 80px;
    margin-top: 100px;
}

.on-board-overhaul {
    text-align: start;
}

.contentofOverhaul {
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.text-columnofOverhaul {
    flex: 1;
    text-align: start;
    margin-right: 20px;
    margin-left: 50px;
}
  
.image-column {
    flex: 1;
    margin-right: 20px;
    
}
  
.image-column img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 950px) {
    .headingofOverhaul {
        font-size: 27px;
        margin-left: 20px;
    }

    .contentofOverhaul {
        flex-direction: column;
    }


    .text-columnofOverhaul,
    .image-column {
        flex: 1;
        margin-left: 20px;
        margin-right: 20px;
    }

    .image-column {
        margin-right: 0;
        order: 1;
    }
}