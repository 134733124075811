.header-wrapper {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-container {
  position: absolute;
  top: 50%;
  left: 8vw;
  text-align: start;
  z-index: 1;
  max-width: calc(100% - 240px);
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.heading {
  font-size: 60px;
  color: black;
  animation: slideInFromTop 1s ease-in-out;
}

.company-icon-header {
  height: 150px;
  width: auto;
  mix-blend-mode: multiply;
  position: absolute;
  top: 3vw;
  left: 8vw;
  z-index: 2;
}

.navbar.navbar-light.bg-light {
  background-color: transparent !important;
  top: 4vw;
  width: 100%;
  position: absolute;
}

.navbar.navbar-light.bg-light .container-fluid {
  width: 90%;
}

.navbar-nav .nav-link {
  color: black;
  font-weight: bold;
  font-size: large;
}

.nav-item.active .nav-link:hover {
  color: #20B2AA;
}

.btn-primary:hover {
  color: #20B2AA !important;
}

.navbar-brand .company-icon-header {
  display: block;
}

.nav-link {
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
}

.nav-link:hover,
.nav-item.active .nav-link {
  color: #0056b3;
}

.mega-menu-content {
  margin-top: 10px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 10px 10px;
  background: linear-gradient(to right, #7d7d4e, #298782);
}

.mega-menu-content .row {
  display: flex;
  justify-content: space-around;
}

.mega-menu-content .col {
  flex: 1;
  margin-top: 10px;
  padding: 0 15px;
  border-right: 1px solid #ddd6d6;
}

.mega-menu-content .col:last-child {
  border-right: none;
}

.mega-menu-content .col ul {
  list-style: none;
  padding: 0;
}

.mega-menu-content .col ul li {
  margin-top: 20px;
}

.mega-menu-content .col ul li a {
  position: relative;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.mega-menu-content .col ul li a:hover {
  color: #20B2AA;
}

.mega-menu-content .col ul li a::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid black;
  border-right: 5px solid transparent;
  transform: translateY(-50%) rotate(0deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.mega-menu-content .col ul li a:hover::before {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .company-icon-header {
    height: 80px;
    margin-top: 3vw;
  }

  .heading {
    font-size: 30px;
    margin: 0;
  }

  .navbar-toggler {
    margin-top: -50px;
    position: absolute;
    top: 20vw;
    right: 10px;
  }

  .navbar-toggler.custom-toggler {
    background-color: transparent;
  }

  .curtain-menu-header {
    position: absolute;
    max-height: calc(100vh - 0);
    top: 0;
    left: 0;
    width: 78%;
    height: 100%;
    background: linear-gradient(to right, #7d7d4e, #298782);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    min-height: 100%;
  }

  .curtain-menu-items {
    list-style-type: none;
    color: black;
    margin: 0;
    padding: 0;
  }

  .curtain-menu-items li {
    margin-bottom: 30px;
  }

  .curtain-menu-items a {
    color: black;
    text-decoration: none;
    font-size: 35px;
  }

  .custom-button {
    font-size: 35px !important;
  }

  .panel {
    position: static;
    max-width: 150px;
    overflow: auto;
  }

  .panel ul {
    padding-left: 0;
    margin: 0;
  }

  .panel ul li {
    margin-bottom: 10px;
  }

  .panel ul li a {
    font-size: 15px;
    color: black;
    text-decoration:dotted;
  }

  .back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1001;
    color: black; 
  }

  .back-button img {
    width: 20px;
    height: 20px;
  }

  .panel.collapse.show {
    overflow-y: auto;
    max-height: 250px;
  }
}

































