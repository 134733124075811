.headingofMEengines {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 40px;
    margin-top: 100px;
}

.contentOfMEengines {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    margin-left: 50px;
    margin-top: 80px;
}

@media screen and (max-width: 950px) {
    .headingofMEengines {
        font-size: 27px;
        margin-left: 20px;
    }

    .contentOfMEengines {
        flex-direction: column;
        margin-left: 20px;
        margin-top: 40px;
    }
}