.headingofDocking {
    color: gray;
    font-size: 30px;
    text-align: start;
    margin-top: 100px;
    margin-left: 50px;
}

.secondaryHeadingofDocking {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 80px;
}

.services-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 100px;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
}
  
.services-column {
    flex: 1;
    max-width: 45%;
    margin: 10px;
    font-size: 18px;
}
  
  
.services-column h2 {
    font-size: 20px;
    margin-bottom: 30px;
}
  
.services-list {
    list-style-type: circle;
    padding: 0;
}
  
.services-list li {
    margin-bottom: 5px;
}

.services-column p {
    font-size: 18px;
}

.extraParagraph {
    font-style: italic;
}

@media screen and (max-width: 950px) {
    .headingofDocking {
        font-size: 17px;
        text-align: start;
        margin-top: 100px;
        margin-left: 50px;
    }
    
    .secondaryHeadingofDocking {
        font-size: 28px;
        text-align: start;
        margin-left: 50px;
        margin-bottom: 80px;
    }

    .headingofDocking {
        margin-left: 20px; /* Adjust margin for smaller screens */
    }
    
    .secondaryHeadingofDocking {
        margin-left: 20px; /* Adjust margin for smaller screens */
    }

    .services-column {
        max-width: 100%; /* Adjust column width for smaller screens */
    }
}
  
  