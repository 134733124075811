.headingofPressureMeasure {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 40px;
    margin-top: 100px;
}

.pressure-measure {
    text-align: start;
}


.contentofPressure {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    margin-left: 50px;
}
  
.text-columnofPressure {
    flex: 1;
    text-align: start;
    margin-right: 20px;
}
  
.image-column {
    flex: 1;
}
  
.image-column img {
    width: 100%;
    height: auto
}

@media screen and (max-width: 950px) {
    .headingofPressureMeasure {
        font-size: 27px;
    }

    .headingofPressureMeasure {
        margin-left: 20px;
    }

    .contentofPressure {
        flex-direction: column;
        margin-left: 20px;
    }

    .text-columnofPressure,
    .image-column {
        flex: 1;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .image-column img {
        width: 100%;
        height: auto;
    }
}