.headingofEngineering {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 80px;
    margin-top: 100px;
}

.engineering-services p {
    margin-left: 50px;
    font-size: 18px;
    margin-bottom: 80px;
}

.engineering-services {
    max-width: 2000px;
    margin: 0 auto;
    padding: 20px;
  }
  
.contentofEng {
    display: flex;
    justify-content: space-between;
}
  
.text-columnofEng {
    flex: 1;
    margin-right: 20px;
}
  
.image-column {
    flex: 1;
}
  
.image-column img {
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 950px) {
    .headingofEngineering {
        font-size: 28px;
    }
    

    .headingofEngineering {
        margin-left: 20px;
    }

    .engineering-services p {
        margin-left: 20px;
    }

    .contentofEng {
        flex-direction: column;
    }

    .text-columnofEng,
    .image-column {
        flex: 1;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .image-column img {
        width: 100%;
        height: auto;
    }
}
  
  