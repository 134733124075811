.heading-of-Lubricator-page {
  color: gray;
  font-size: 30px;
  text-align: start;
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 10px;
}

.second-heading-of-Lubricator-page {
  color: #20B2AA;
  font-size: 58px;
  text-align: start;
  margin-bottom: 80px;
  margin-left: 60px;
}

.middle-heading {
  color: gray;
  font-size: 30px;
  text-align: start;
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 80px;
}

.row {
  display: flex;
  margin-left: 60px;
  font-size: 18px;
}

.row-item {
  flex: 1;
  margin: 0 10px;
  margin-left: 60px;
  text-align: center;
}

.row-image {
  object-fit: cover;
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.last-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.last-images-container img {
  margin-bottom: 10px;
}


.last-images-container p {
  text-align: center;
  font-size: 18px;
  padding: 20px;
}

.Lubricatorlist {
  margin-top: 70px;
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 950px) {

  .heading-of-Lubricator-page {
    font-size: 17px;
  }
  
  .second-heading-of-Lubricator-page {
    font-size: 28px;
  }

  .heading-of-Lubricator-page,
  .second-heading-of-Lubricator-page,
  .middle-heading {
    margin-left: 20px;
  }

  .row {
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }

  .row-item {
    flex: none;
    margin: 10px 0;
  }

  .row-image {
    width: 100%;
    height: auto;
  }

  .last-images-container {
    margin-top: 10px;
  }

}

  