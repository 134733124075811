.footer {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    background-color: white;
    flex-wrap: wrap;
}

.FooterColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 80px;
}

.FooterImgColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.company-icon-footer {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 100%;
}

.FooterColumn h4 {
    margin-bottom: 10px;
}

.FooterColumn p {
    font-size: 15px;
}

.ReadMoreSection {
    width: 100%;
    text-align: end;
    margin-top: 20px;
}

.ReadMoreButton {
    background-color: white;
    color: grey;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.ReadMoreContent {
    margin-top: 20px;
    text-align: left;
    padding: 20px;
    border-top: 1px solid #ccc;
}

.ReadMoreLinks p {
    font-size: 15px; /* Match the font size of <p> */
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

.ReadMoreLinks a {
    color: #007BFF;
    text-decoration: none;
}

.ReadMoreLinks a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 950px) {
    .company-icon-footer {
        height: 80px;
        margin-top: 80px;
    }

    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        padding: 5px;
    }

    .FooterColumn {
        padding: 0 50px;
        margin-top: 20px;
    }

    .FooterImgColumn {
        margin-top: 20px;
    }

    .FooterColumn h4 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

