.headingofPerformance {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 80px;
    margin-top: 100px;
}

.performance-measurement {
    text-align: start;
}
  
.contentofPerformance {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    
}
  
.text-columnofPerformance {
    flex: 1;
    text-align: start;
    margin-right: 20px;
    margin-left: 50px;
}
  
.image-column {
    flex: 1;
}
  
.image-column img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 950px) {
    .headingofPerformance {
        font-size: 27px;
    }

    .headingofPerformance {
        margin-left: 20px;
    }

    .text-columnofPerformance {
        flex: 1;
        margin-left: 20px;
        margin-right: 20px;
    }

    .image-column {
        flex: 1;
    }
}
  
  