.headingofAdjustmentV {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 40px;
    margin-top: 100px;
}

.adjustment-valves {
    text-align: start;
}

.adjustment-valves p {
    margin-bottom: 80px;
}
  
.contentofAdjustmentV {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    margin-left: 50px;
    margin-top: 80px;
}
  
.text-columnofAdjustmentV {
    flex: 1;
    text-align: start;
    margin-right: 20px;
}
  
.image-column {
    flex: 1;
}
  
.image-column img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 950px) {
    .headingofAdjustmentV {
        font-size: 27px;
        margin-left: 20px;
    }

    .contentofAdjustmentV {
        flex-direction: column;
        margin-left: 20px;
        margin-top: 40px;
    }

    .text-columnofAdjustmentV,
    .image-column {
        flex: 1;
        margin-right: 20px;
    }

    .image-column {
        margin-left: 20px;
    }
}
  