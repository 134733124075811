.headingofOverhaulFuel {
    color:#20B2AA;
    font-size: 50px;
    text-align: start;
    margin-left: 50px;
    margin-bottom: 40px;
    margin-top: 100px;
}

.overhaul-fuel p {
    margin-left: 50px;
}

@media screen and (max-width: 950px) {
    .headingofOverhaulFuel {
        font-size: 27px;
        margin-left: 20px;
    }

    .overhaul-fuel p {
        margin-left: 20px;
    }
}