.contact-page-container {
  margin-bottom: 80px;
  margin-top: 80px;
  text-align: center;
}

.column-heading {
  color: #20B2AA;
  font-size: 40px;
  font-style: bold;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  opacity: 0;
  transform: translateX(-50px);
  animation: slideFromLeft 0.5s ease-out forwards;
}

.columns-container {
  display: flex;
  justify-content: space-around;
}

.contact-info-column,
.map-column {
  flex: 1;
  padding: 30px;
}

.contact-info-column {
  font-size: 17px;
  text-align: center;
}

.contact-info {
  display: flex;
}

.address-column,
.phone-email-column {
  flex: 1;
  padding: 0 5px;
}

.phone-email-column p {
  margin-top: 20px;
}


@keyframes slideFromLeft {
  from {
      opacity: 0;
      transform: translateX(-50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@media only screen and (max-width: 950px) {
  .column-heading {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .columns-container {
    flex-direction: column;
  }

  .contact-info-column,
  .map-column {
    flex: none;
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
  }

  .map-column iframe {
    width: 100%;
    height: 300px;
  }
}

  
  
  